<template>
  <div class="vuse-content-wrapper supervisions">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <help class="help--breadcrumb" :helpId="32" />
    </v-row>
    <v-card elevation="2">
      <v-toolbar color="primary" dark dense flat>
        <v-toolbar-title class="text-h6"> Gestor de Supervisión </v-toolbar-title>
        <v-spacer />
        <v-chip
          class="mr-0"
          small
          :color="typeOptions.find((item) => item.id === 0).color"
          text-color="white"
        >
          {{ typeOptions.find((item) => item.id === 0).name }}
        </v-chip>
        <v-chip
          class="mr-0"
          small
          :color="typeOptions.find((item) => item.id === 1).color"
          text-color="white"
        >
          {{ typeOptions.find((item) => item.id === 1).name }}
        </v-chip>
      </v-toolbar>

      <!-- COMPANIES TREEVIEW -->
      <v-skeleton-loader
        v-if="loadingCompanies"
        type="list-item"
        class="d-flex align-center px-4"
      >
        <v-skeleton-loader type="avatar" class="skeleton__avatar--x-mall mr-1" />
        <v-skeleton-loader type="avatar" />
        <v-skeleton-loader type="chip" />
        <v-skeleton-loader type="table-cell" class="ml-2" />

        <v-spacer />
        <v-skeleton-loader type="avatar@3" class="d-flex" />
      </v-skeleton-loader>
      <v-treeview
        v-model="tree"
        :items="companies"
        activatable
        item-key="id"
        item-children="children"
        open-on-click
        v-else
      >
        <template v-slot:prepend="{ item, open }">
          <div class="float-left">
            <v-icon v-if="item.level_id === 1">
              {{ open ? 'mdi-city-variant-outline' : 'mdi-city-variant' }}
            </v-icon>
            <v-icon v-if="item.level_id === 2">
              {{ open ? 'mdi-office-building-outline' : 'mdi-office-building' }}
            </v-icon>

            <v-icon v-if="item.level_id === 3">
              {{ open ? 'mdi-store-outline' : 'mdi-store' }}
            </v-icon>

            <v-chip small v-if="item.level_id === 1"
              >Central
              {{ item.countries[0] ? `- ${item.countries[0].name}` : '' }}</v-chip
            >
            <v-chip small v-if="item.level_id === 2">Master</v-chip>
          </div>
        </template>
        <template v-slot:append="{ item }">
          <span class="float-right" v-if="item.request_counts">
            <v-chip
              small
              pill
              :color="typeOptions.find((item) => item.id === 0).color"
              text-color="white"
            >
              {{ item.request_counts.type_0 }}
            </v-chip>
            <v-chip
              small
              pill
              :color="typeOptions.find((item) => item.id === 1).color"
              text-color="white"
            >
              {{ item.request_counts.type_1 }}
            </v-chip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click.prevent="openTable(item)" v-bind="attrs" v-on="on">
                  <v-icon> mdi-text-box-search-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver solicitudes</span>
            </v-tooltip>
          </span>
        </template>
      </v-treeview>
    </v-card>
    <!-- /COMPANIES TREEVIEW -->

    <!-- REQUESTS SECTION -->
    <v-card elevation="2" class="mt-4">
      <v-toolbar color="primary" dark dense flat>
        <v-chip
          small
          v-if="selectedCompany.level_id === 1"
          color="grey lighten-4"
          text-color="primary"
          >Central
          {{
            selectedCompany.countries && selectedCompany.countries[0]
              ? `- ${selectedCompany.countries[0].name}`
              : ''
          }}
        </v-chip>
        <v-chip
          small
          v-if="selectedCompany.level_id === 2"
          color="grey lighten-4"
          text-color="primary"
          >Master</v-chip
        >
        <v-toolbar-title class="text-body-2">
          {{ selectedCompany.name }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn small outlined color="white accent-4" @click="handleCreate()">
          Crear Solicitud
        </v-btn>
      </v-toolbar>

      <v-row class="mx-0 stats">
        <!-- STATUS STATS -->
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <v-card
            class="mx-auto elevation-4 pa-2 d-flex flex-column justify-end"
            color="primary"
            dark
            style="width: 100%; max-width: 400px"
          >
            <v-row no-gutters>
              <v-chip
                :color="statusOptions.find((status) => status.id === 0).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular indeterminate size="20" v-if="loadingStats" />
                  <span v-else>
                    {{ statusStats.status_0 ? statusStats.status_0 : 0 }}
                  </span>
                </v-chip>
                {{ statusOptions.find((status) => status.id === 0).name }}
              </v-chip>

              <v-chip
                :color="statusOptions.find((status) => status.id === 1).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular indeterminate size="20" v-if="loadingStats" />
                  <span v-else>
                    {{ statusStats.status_1 ? statusStats.status_1 : 0 }}
                  </span>
                </v-chip>
                {{ statusOptions.find((status) => status.id === 1).name }}
              </v-chip>

              <v-chip
                :color="statusOptions.find((status) => status.id === 2).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular indeterminate size="20" v-if="loadingStats" />
                  <span v-else>
                    {{ statusStats.status_2 ? statusStats.status_2 : 0 }}
                  </span>
                </v-chip>
                {{ statusOptions.find((status) => status.id === 2).name }}
              </v-chip>
            </v-row>
            <v-divider dark class="mt-1" />
            <v-card-actions> Solicitudes por estatus </v-card-actions>
          </v-card>
        </v-col>
        <!-- PRIORITY STATS -->
        <v-col cols="12" sm="6" lg="4" md="4">
          <v-card
            class="elevation-4 pa-2"
            color="primary"
            dark
            style="width: 100%; max-width: 400px"
          >
            <v-row no-gutters>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" color="grey lighten-4">
                    <v-chip small class="grey darken-4">
                      <v-progress-circular indeterminate size="20" v-if="loadingStats" />
                      <span v-else>
                        {{ priorityStats.priority_1 ? priorityStats.priority_1 : 0 }}
                      </span>
                    </v-chip>
                    <v-rating
                      dense
                      readonly
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      half-icon="mdi-star-half-full"
                      color="orange"
                      background-color="orange"
                      length="3"
                      size="20"
                      :value="1"
                    />
                  </v-chip>
                </template>
                <span>Baja</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-on="on"
                    v-bind="attrs"
                    color="grey lighten-4"
                    text-color="white"
                  >
                    <v-chip small class="grey darken-4">
                      <v-progress-circular indeterminate size="20" v-if="loadingStats" />
                      <span v-else>
                        {{ priorityStats.priority_2 ? priorityStats.priority_2 : 0 }}
                      </span></v-chip
                    >
                    <v-rating
                      dense
                      readonly
                      length="3"
                      size="20"
                      :value="2"
                      color="orange"
                      full-icon="mdi-star"
                      background-color="orange"
                      empty-icon="mdi-star-outline"
                      half-icon="mdi-star-half-full"
                    />
                  </v-chip>
                </template>
                <span>Media</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-on="on"
                    v-bind="attrs"
                    color="grey lighten-4"
                    text-color="white"
                  >
                    <v-chip small class="grey darken-4">
                      <v-progress-circular indeterminate size="20" v-if="loadingStats" />
                      <span v-else>
                        {{ priorityStats.priority_3 ? priorityStats.priority_3 : 0 }}
                      </span>
                    </v-chip>
                    <v-rating
                      dense
                      readonly
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      half-icon="mdi-star-half-full"
                      color="orange"
                      background-color="orange"
                      length="3"
                      size="20"
                      :value="3"
                    />
                  </v-chip>
                </template>
                <span>Alta</span>
              </v-tooltip>
            </v-row>
            <v-divider dark class="mt-1" />
            <v-card-actions> Solicitudes por prioridad </v-card-actions>
          </v-card>
        </v-col>
        <!-- ATENTION TIME -->
        <v-col cols="12" sm="6" lg="4" md="4" class="d-flex">
          <v-card
            dark
            class="pa-2 d-flex flex-column justify-end"
            style="width: 100%; max-width: 400px"
            color="primary"
          >
            <v-card-title
              style="word-break: break-word; height: 100%"
              class="text-white ma-0 pa-1 align-start"
              >{{ attentionTime }}
            </v-card-title>
            <v-divider dark class="mt-1" />

            <v-card-actions> Tiempo promedio de respuesta </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- REQUESTS LIST -->
      <v-data-table
        dense
        :key="dataTableIndex"
        :headers="headers"
        :items="requests"
        :locale="locale"
        class="elevation-1 my-6"
        :hide-default-footer="true"
        :items-per-page="itemsPerPage ? itemsPerPage : 20"
        :loading="loadingRequests"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            small
            v-if="item.status !== null"
            text-color="white"
            :color="statusOptions.find((status) => status.id === item.status).color"
            >{{ statusOptions.find((status) => status.id === item.status).name }}</v-chip
          >
        </template>
        <template v-slot:[`item.priority`]="{ item }">
          <v-rating
            empty-icon="mdi-star-outline"
            full-icon="mdi-star"
            dense
            hover
            color="orange"
            background-color="orange"
            length="3"
            size="20"
            :value="item.priority"
            readonly
          />
        </template>
        <template v-slot:[`item.evaluador`]="{ item }">
          <v-chip class="pointer" pill>
            <v-avatar max-height="100%" left>
              <v-img
                v-if="item.user.avatar && item.user.avatar.route"
                :src="item.user.avatar.route"
              />
              <v-img
                v-else
                src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png"
              />
            </v-avatar>
            {{ item.user.fullname }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-space-between">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="mr-1"
                  @click="handleTimeline(item)"
                >
                  mdi-history
                </v-icon>
              </template>
              <span>Línea de tiempo</span>
            </v-tooltip>
            <v-tooltip top v-if="item.showEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mr-1" @click="handleEdit(item)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click.prevent="handleDelete(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:no-data
          ><div class="text-center" />
          {{ 'No hay registros' }}
        </template>
        <template v-slot:no-results
          ><div class="text-center" />
          {{ 'No hay resultados para su búsqueda' }}
        </template>
      </v-data-table>
      <div class="text-center py-2" style="margin: 10px 0px">
        <v-pagination
          @input="pagination($event)"
          color="primary"
          v-model="page"
          :length="pages"
        />
      </div>
      <!-- /REQUESTS LIST -->
    </v-card>
    <!-- /REQUESTS SECTION -->

    <!-- CREATE OR UPDATE REQUIREMENT WINDOW -->
    <modal name="crudModal" :width="'90%'" :adaptive="true">
      <v-card elevation="0" class="ma-4">
        <h2 class="text-center" v-if="flow === 'create'">Crear Solicitud</h2>
        <h2 class="text-center" v-if="flow === 'edit'">
          Editar Solicitud #{{ item.id }}
        </h2>

        <v-card-text>
          <span v-if="flow === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="mx-0 mt-2">
            <v-row class="mx-0 align-center" style="width: 100%">
              <v-row class="mx-0" v-if="flow === 'edit'" style="width: 100%">
                <v-col cols="12" sm="6" md="3">
                  <span class="caption">Estatus</span>
                  <multiselect
                    :options="statusOptions"
                    v-model="selectedStatus"
                    label="name"
                    track-by="id"
                    :allow-empty="false"
                    placeholder="Seleccionar estatus"
                  />
                </v-col>
              </v-row>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="Asunto*"
                  v-model="subject"
                  :rules="[rules.required, rules.max]"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-textarea
                  counter
                  rows="1"
                  auto-grow
                  label="Descripción"
                  v-model="description"
                  :rules="[rules.required, rules.max]"
                />
              </v-col>
              <v-col cols="12" sm="6" class="d-flex flex-row align-center">
                <span class="mr-2">Prioridad</span>
                <v-row no-gutters align="center" class="flex-nowrap">
                  <v-rating
                    hover
                    length="3"
                    v-model="priority"
                    :rules="[rules.required]"
                  />
                  <span v-if="priority === 1">Baja</span>
                  <span v-if="priority === 2">Media</span>
                  <span v-if="priority === 3">Alta</span>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6">
                <v-row no-gutters align="center">
                  <span>Tipo</span>
                  <v-radio-group
                    hide-details
                    v-model="selectedType"
                    :rules="[rules.required]"
                    class="mx-2"
                  >
                    <v-radio
                      v-for="(item, id) in typeOptions"
                      :key="id"
                      :label="item.name"
                      :value="item.id"
                      class="my-0 ml-2"
                    />
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  type="date"
                  label="Fecha de vencimiento"
                  v-model="expiredAt"
                  hide-details
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <span class="caption">Evaluación asociada</span>
                <multiselect
                  :key="evaluationIndex"
                  :options="assignedEvaluations"
                  track-by="id"
                  :loading="loadingEvaluations"
                  :custom-label="evaluationName"
                  v-model="selectedEvaluation"
                  placeholder="Seleccionar evaluación"
                  :disabled="flow === 'edit' ? true : false"
                  @search-change="getEvaluations"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <span class="caption">Evaluador</span>
                <multiselect
                  :loading="loadingAuditors"
                  :options="auditors"
                  v-model="selectedAuditor"
                  :custom-label="fullName"
                  label="fullname"
                  track-by="id"
                  placeholder="Seleccionar evaluador"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <span class="caption">Periodicidad</span>
                <multiselect
                  :options="periodicity"
                  v-model="selectedPeriodicity"
                  label="name"
                  track-by="id"
                  placeholder="Seleccionar periodicidad"
                />
              </v-col>

              <v-col cols="12">
                <v-row no-gutters class="mx-0 flex-column align-center">
                  <h2 class="mb-3">Asignación de Colaboradores</h2>

                  <multiselect
                    :loading="loadingCollaborators"
                    track-by="id"
                    label="fullName"
                    placeholder="Seleccionar"
                    v-model="selectedCollaborators"
                    :options="collaborators"
                    :multiple="true"
                    :preserve-search="false"
                    :close-on-select="false"
                    @search-change="getCollaborators"
                  />
                  <span style="font-size: 13px"
                    >Los colaboradores son responsables de atender esta solicitud hasta
                    concluirla</span
                  >
                </v-row>
              </v-col>
              <v-col cols="12" v-if="flow === 'edit'">
                <v-divider class="my-4" />
                <h2 class="text-center mb-4">Imágenes</h2>
                <span>*Evidencias relacionadas a este requerimiento/supervisión</span>

                <v-row align="center">
                  <v-col cols="12" sm="6" md="4" class="mx-sm-auto">
                    <dropzone
                      ref="imgDropzone"
                      id="imgDropzone"
                      :options="imgDropzoneOptions"
                      v-on:vdropzone-canceled="canceledImageUpload"
                      v-on:vdropzone-success="successImageUpload"
                      v-on:vdropzone-sending="sendingImageEvent"
                      v-on:vdropzone-error="uploadError"
                    />
                  </v-col>
                  <v-col cols="12" md="8" class="text-center">
                    <p v-if="images && images.length == 0">
                      Aún no se han cargado imagenes
                    </p>
                    <v-row justify="center">
                      <v-col v-for="image in images" :key="image.id">
                        <a
                          :href="image.route"
                          target="_blank"
                          rel="noopener"
                          download
                          class="d-flex"
                        >
                          <v-img
                            :src="image.route"
                            alt=""
                            :lazy-src="image.route"
                            max-height="150"
                            max-width="150"
                            min-height="100"
                            min-width="100"
                            aspect-ratio="1"
                            contain
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-1"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  class="mx-auto"
                                  indeterminate
                                  color="grey lighten-5"
                                />
                              </v-row>
                            </template>
                            <v-btn
                              color="primary"
                              fab
                              x-small
                              absolute
                              right
                              title="Eliminar archivo"
                              style="right: 0"
                              @click.prevent="deleteFile(image.id)"
                              ><i class="fa fa-remove"
                            /></v-btn>
                          </v-img>
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="row">
          <v-btn
            class="mx-auto"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="handleSaveItem()"
          >
            Crear
          </v-btn>
          <v-btn
            class="mx-auto"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="handleSaveItem()"
          >
            Guardar
          </v-btn>

          <v-btn class="mx-auto" color="primary" @click="reset"> Limpiar </v-btn>
          <v-btn class="mx-auto" color="primary" @click="$modal.hide('crudModal')">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </modal>
    <!-- /CREATE OR UPDATE REQUIREMENT WINDOW -->

    <!-- REQUEST TIME LINE -->
    <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-toolbar-title class="text-body-2"> Historial y seguimiento</v-toolbar-title>
          <v-spacer />

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small text v-bind="attrs" v-on="on" @click="dialog = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>

        <div class="pa-4 pl-8">
          Solicitud #{{ item.id }}
          <br />
          Asunto: {{ item.subject }}
        </div>
        <v-timeline align-top dense>
          <v-timeline-item color="primary" small v-if="item.status !== 2">
            <v-expansion-panels flat :value="expantionControl">
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0">
                  Añadir actualización
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field label="Asunto" v-model="timelineSubject" />
                  <v-textarea
                    counter
                    label="Descripción"
                    v-model="timelineDescription"
                    rows="1"
                    auto-grow
                  />

                  <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Subir una fotografia"
                    prepend-icon="mdi-camera"
                    v-model="selectedTimelineFile"
                  />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" text @click="handleSaveTimelineItem">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-timeline-item>

          <v-timeline-item color="primary" small v-if="loadingRequestsLogs">
            <v-row class="mx-0">
              <v-col cols="12">
                <v-skeleton-loader type="chip" />
              </v-col>
              <v-col>
                <v-skeleton-loader type="list-item-two-line, image" />
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item
            class="pr-md-5"
            color="primary"
            small
            v-for="(item, id) in timelineItems"
            :key="id"
          >
            <v-row class="mx-0">
              <v-col cols="12" sm="3" class="pt-0">
                <strong>{{ timelineDateString(item.created_at, 'es-VE') }}</strong>
              </v-col>
              <!-- <v-col cols="12" sm="9"> Esta solicitud se ha vencido </v-col> -->

              <!-- CODE FOR CREATED AND UPDATED STATUS -->
              <v-col v-if="item.type === 0 && item.request_status === 0" class="pt-0">
                <strong>Se creó la solicitud</strong>
              </v-col>
              <v-col v-if="item.type === 0 && item.request_status !== 0" class="pt-0">
                <strong>Se actualizó el estatus a </strong>
                <br />
                <v-chip
                  :color="
                    statusOptions.find((status) => status.id === item.request_status)
                      .color
                  "
                  dark
                  >{{
                    statusOptions.find((status) => status.id === item.request_status).name
                  }}</v-chip
                >
              </v-col>

              <!-- CODE FOR COLLABORATORS -->
              <v-col v-if="item.type === 1" class="pt-0">
                <strong>Se asignó como colaborador a</strong>
                <v-chip pill>{{
                  ` ${item.user_sumary.name} ${item.user_sumary.lastname}`
                }}</v-chip>
              </v-col>
              <v-col v-if="item.type === 2" class="pt-0">
                <strong>Se eliminó como colaborador a</strong>
                <v-chip pill>{{
                  ` ${item.user_sumary.name} ${item.user_sumary.lastname}`
                }}</v-chip>
              </v-col>
              <!-- CODE FOR USER EVENTS -->
              <v-col v-if="item.type === 3" class="pt-0">
                <strong v-if="item.subject && item.subject !== ''">
                  {{ item.subject }}
                </strong>
                <p v-if="item.description && item.description !== ''">
                  {{ item.description }}
                </p>
              </v-col>
              <!-- CODE FOR EXPIRATION -->
              <v-col v-if="item.type === 4" class="pt-0">
                <strong>La solicitud venció</strong>
              </v-col>
              <v-col cols="12" v-if="item.uploads[0]" class="pt-0">
                <a rel="noopener" target="_blank" :href="item.uploads[0].route" download>
                  <v-img
                    v-if="item.uploads[0]"
                    :src="item.uploads[0].route"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    contain
                    height="200px"
                    target="_blank"
                    rel="noopener"
                  />
                </a>
              </v-col>
              <v-col
                cols="12"
                v-if="item.type === 3"
                class="caption d-flex justify-end align-center"
              >
                <v-icon small>mdi-account</v-icon
                >{{ `${item.user_sumary.name} ${item.user_sumary.lastname}` }}
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card>
    </v-dialog>
    <!-- /REQUEST TIME LINE -->
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import Dropzone from 'vue2-dropzone'
  import itemsPeriodicity from '@/data/periodicity'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'Supervisions',
    components: { Dropzone },
    data: function () {
      return {
        config: {},
        loadingCompanies: false,
        loadingStats: false,
        loadingRequests: false,
        loadingRequestsLogs: false,
        loadingEvaluations: false,
        loadingCollaborators: false,
        loadingAuditors: false,
        expantionControl: [],
        initiallyOpen: ['public'],
        valid: true,
        dateRange: '',
        tree: [],
        selectedType: null,
        select: 'NY',
        createdAt: '',
        toDay: new Date(),
        flow: '',
        item: {},
        timelineDescription: null,
        description: '',
        priority: 1,
        photo: [],
        subject: '',
        timelineSubject: null,
        periodicity: itemsPeriodicity,
        selectedPeriodicity: null,
        selectedFile: null,
        selectedTimelineFile: null,
        requests: [],
        collaborators: [],
        selectedStatus: null,
        selectedAuditor: null,
        selectedCompany: null,
        assignedEvaluations: [],
        selectedEvaluation: null,
        selectedCollaborators: [],
        expiredAt: null,
        evaluationIndex: 0,
        dataTableIndex: 1,
        itemsPerPage: 10,
        page: null,
        pages: null,
        dialog: false,
        crudModal: false,
        timelineItems: [],
        auditors: [],
        headers: [
          {
            text: '#',
            align: 'start',
            value: 'id',
          },
          {
            text: 'Asunto',
            align: 'start',
            sortable: false,
            value: 'subject',
            width: '200px',
          },
          { text: 'Prioridad', value: 'priority', align: 'center' },
          { text: 'Estatus', value: 'status', align: 'center' },
          { text: 'Evaluador', value: 'evaluador', align: 'center' },
          { text: 'Tipo de Solicitud', value: 'typeText', align: 'center' },
          {
            text: 'Fecha de Vencimiento',
            value: 'expirationText',
            align: 'center',
            width: '100px',
          },
          { text: 'Evaluación asociada', value: 'origin' },
          { text: 'Periodicidad', value: 'periodicityText', align: 'center' },
          {
            text: 'Acciones',
            value: 'actions',
            width: '100px',
          },
        ],
        loading: false,
        typeOptions: [
          { id: 0, name: 'Supervisión', color: '#008fc9' },
          { id: 1, name: 'Requerimiento', color: '#84919a' },
        ],
        statusOptions: [
          {
            id: 0,
            name: 'Creado',
            color: 'deep-orange darken-2',
            // avatarColor: "deep-orange darken-4",
          },
          {
            id: 1,
            name: 'Atendido',
            color: 'amber darken-2',
            // avatarColor: "amber darken-4",
          },
          {
            id: 2,
            name: 'Concluido',
            color: 'green darken-2',
            // avatarColor: "green darken-4",
          },
        ],
        priorityOptions: [
          { id: 0, name: 'Baja' },
          { id: 1, name: 'Media' },
          { id: 2, name: 'Alta' },
        ],

        companies: [],
        statusStats: {},
        priorityStats: {},
        attentionTime: '',
        imgDropzoneOptions: {
          url: '',
          method: 'POST',
          thumbnailWidth: 100,
          addRemoveLinks: true,
          acceptedFiles: '.jpg, .jpeg, .png,',
          dictDefaultMessage:
            "<i class='fa fa-cloud-upload'></i> Arrastra y suelta, o haz clic para seleccionar la(s) imagen(es) de tu dispositivo",
          maxFilesize: 0.5,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: this.$session.get('tokenSession')
              ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
              : 'Bearer ',
          },
        },
        attrs: {
          class: 'mb-6',
          boilerplate: true,
          elevation: 2,
        },
        navigationElements: [
          {
            text: 'Control y Supervisión',
            disabled: false,
            href: '/business/supervision-control/admin-panel',
          },
          {
            text: 'Supervisiones',
            disabled: true,
            href: '',
          },
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.selectedCompany = this.company
      this.getCompanies()
      this.getRequests()
      this.getStats()
      this.getAuditors()
      this.getEvaluations()
      this.getCollaborators()
    },
    computed: {
      ...mapGetters(['locale', 'userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      userId() {
        if (this.$session.get('tokenSession')) {
          return decryptData(this.$session.get('userId'))
        } else {
          return false
        }
      },
      images() {
        if (this.item.uploads && this.item.uploads.length > 0) {
          return this.item.uploads.filter((file) => file.type === 'image')
        }
        return []
      },
      // filter() {
      //   return this.caseSensitive
      //     ? (item, search, textKey) => item[textKey].indexOf(search) > -1
      //     : undefined;
      // },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      timelineDateString(date, locales) {
        try {
          return new Date(date).toLocaleDateString(locales, {
            day: 'numeric',
            month: 'long',
            timeZone: 'UTC',
          })
        } catch (error) {
          console.warn(error)
          return 'Invalid data'
        }
      },
      handleExpirationData(date, message) {
        const expiredDate = new Date(date)
        if (this.toDay.getTime() > expiredDate.getTime()) {
          return message
        } else {
          return date.split('-').reverse().join('-')
        }
      },
      async pagination(page) {
        this.page = page
        await this.getRequests()
      },
      openTable(item) {
        this.selectedCompany = item
        this.getStats()
        this.getRequests()
        this.getEvaluations()
        this.getCollaborators()
        this.dataTableIndex++
      },
      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
        this.priority = 1
        this.selectedType = 1
        this.selectedAuditor = null
        this.selectedPeriodicity = null
        this.selectedFile = null
        this.selectedStatus = null
        this.selectedCollaborators = null
      },

      handleTimeline(item) {
        this.item = item
        this.timelineItems = []
        this.getRequestLogs()
        this.dialog = true
      },

      evaluationName({ evaluation, id }) {
        if (!evaluation) return ''
        return `${evaluation.name} #${id}`
      },
      fullName({ name, lastname }) {
        if (!name) return ''
        return `${name} ${lastname}`
      },
      collaboratorName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },

      async getCompanies() {
        this.loadingCompanies = true
        this.$axios
          .get(
            'companies/' +
              this.selectedCompany.id +
              '?appends[]=request_counts&with[]=countries&with[]=children.children.children'
          )
          .then((response) => {
            this.companies = [response.data]
            this.selectedCompany = this.companies[0]
            this.loadingCompanies = false
          })
      },
      async getAuditors(search) {
        this.loadingAuditors = true
        this.loadingAuditors = true
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('users?role=auditor_evaluations&limit=30' + searchStr)
          .then((response) => {
            this.auditors = response.data
            this.loadingAuditors = false
          })
      },
      getEvaluations(search) {
        this.loadingEvaluations = true
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'assigned-evaluations?limit=30&company_id=' +
              this.selectedCompany.id +
              searchStr
          )
          .then((response) => {
            this.assignedEvaluations = response.data
            this.assignedEvaluations.forEach((item) => {
              item.fullname = `${item.evaluation.name} #${item.id}`
            })
            this.loadingEvaluations = false
            this.evaluationIndex++
          })
      },
      async getCollaborators(search) {
        this.loadingCollaborators = true
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('users?limit=30&company_id=' + this.selectedCompany.id + searchStr)
          .then((response) => {
            this.collaborators = response.data
            this.collaborators.forEach((item) => {
              if (item.lastname && item.email) {
                item.fullName = `${item.name} ${item.lastname} | ${item.email}`
              } else {
                item.fullName = item.name
              }
              this.loadingCollaborators = false
            })
          })
      },

      async getRequests() {
        this.loadingRequests = true
        this.$axios
          .get(
            'requests?order=created_at&by=desc&&with[]=assignedEvaluation&with[]=user&with[]=collaborators&with[]=uploads&company_id=' +
              this.selectedCompany.id +
              '&page=' +
              this.page
          )
          .then((response) => {
            this.requests = response.data.data

            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.requests.forEach((requirement) => {
              requirement.type === 1
                ? (requirement.typeText = 'Requerimiento')
                : (requirement.typeText = 'Supervisión')

              if (requirement.expired_at) {
                requirement.expirationText = this.handleExpirationData(
                  requirement.expired_at,
                  'Vencido'
                )
              }
              if (this.toDay.getTime() > new Date(requirement.expired_at).getTime()) {
                requirement.expired = true
              } else {
                requirement.expired = false
              }
              if (requirement.assigned_evaluation) {
                requirement.origin =
                  requirement.assigned_evaluation.evaluation.name +
                  ' #' +
                  requirement.assigned_evaluation_id
              } else {
                requirement.origin = 'No posee'
              }
              if (requirement.periodicity) {
                requirement.periodicityText = this.periodicity.find(
                  (item) => item.id === requirement.periodicity
                ).name
              }
              if (requirement.user.name && requirement.user.lastname) {
                requirement.user.fullname = `${requirement.user.name} ${requirement.user.lastname}`
              }
              if (requirement.status === 2) {
                requirement.showEdit = false
              } else {
                requirement.showEdit = true
              }
              requirement.collaborators.forEach((item) => {
                if (item.user_sumary.lastname && item.user_sumary.email) {
                  item.fullName = `${item.user_sumary.name} ${item.user_sumary.lastname} | ${item.user_sumary.email}`
                } else {
                  item.fullName = item.user_sumary.name
                }
              })
            })

            this.loadingRequests = false
          })
      },

      handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.subject = null
        this.description = null
        this.priority = 1
        this.selectedPeriodicity = null
        this.selectedType = 1
        this.selectedEvaluation = null
        this.selectedAuditor = null
        this.expiredAt = null
        this.selectedCollaborators = null
        this.selectedFile = null

        this.$modal.show('crudModal')
      },
      handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.subject = this.item.subject
        this.description = this.item.description
        this.priority = this.item.priority
        this.selectedPeriodicity = this.periodicity.find(
          (item) => item.id === this.item.periodicity
        )
        this.selectedFile = this.item.uploads.filter((file) => file.type === 'image')
        this.selectedType = this.item.type
        this.selectedStatus = this.statusOptions.find(
          (item) => item.id === this.item.status
        )
        this.imgDropzoneOptions.url =
          process.env.VUE_APP_CLOUD_API + 'requests/' + this.item.id
        this.selectedEvaluation = this.item.assigned_evaluation
        this.expiredAt = item.expired_at
        this.selectedAuditor = item.user
        this.selectedCollaborators = item.collaborators
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )
        this.$modal.show('crudModal')
      },

      async handleSaveItem() {
        if (!this.selectedStatus && this.flow === 'edit') {
          this.$swal({
            title: '',
            text: 'Seleccione un estatus válido',
            icon: 'info',
          })
        } else if (!this.selectedAuditor || !this.selectedAuditor.id) {
          this.$swal({
            title: '',
            text: 'Seleccione un evaluador',
            icon: 'info',
          })
        } else if (!this.selectedPeriodicity || !this.selectedPeriodicity.id) {
          this.$swal({
            title: '',
            text: 'Seleccione una periodicidad',
            icon: 'info',
          })
        } else if (
          !this.selectedCollaborators ||
          !this.selectedCollaborators.length > 0
        ) {
          this.$swal({
            title: '',
            text: 'Seleccione por lo menos 1 colaborador',
            icon: 'info',
          })
        } else {
          const formData = new FormData()
          this.config.headers = {
            'Content-Type': 'multipart/form-data',
          }
          if (this.flow === 'create') {
            formData.append('status', 0)
            formData.append('company_id', parseInt(this.selectedCompany.id))
          }
          if (this.flow === 'edit') {
            formData.append('_method', 'patch')
            this.imgDropzoneOptions.url =
              process.env.VUE_APP_CORE_API + 'requests/' + this.item.id
            formData.append('status', parseInt(this.selectedStatus.id))
          }
          if (this.subject && this.subject !== '') {
            formData.append('subject', this.subject)
          }
          if (this.description && this.description !== '') {
            formData.append('description', this.description)
          }
          if (this.expiredAt && this.expiredAt !== '') {
            formData.append('expired_at', this.expiredAt)
          }
          formData.append('priority', this.priority)
          formData.append('type', this.selectedType)
          if (
            this.selectedEvaluation &&
            this.selectedEvaluation.id &&
            this.flow === 'create'
          ) {
            formData.append('assigned_evaluation_id', this.selectedEvaluation.id)
          }
          formData.append('periodicity', this.selectedPeriodicity.id)
          formData.append('user_id', this.selectedAuditor.id)
          if (this.selectedCollaborators && this.selectedCollaborators.length > 0) {
            this.selectedCollaborators.forEach((collaborator, index) => {
              if (collaborator.user_id) {
                formData.append(`collaborators[${index}][id]`, collaborator.id)
                formData.append(`collaborators[${index}][user_id]`, collaborator.user_id)
                formData.append(`collaborators[${index}][main]`, 0)
              } else {
                formData.append(`collaborators[${index}][user_id]`, collaborator.id)
                formData.append(`collaborators[${index}][main]`, 0)
              }
            })
          }

          if (this.flow === 'create') {
            this.$axios.post('requests', formData, this.config).then(() => {
              this.page = 1
              this.getRequests()
              this.getStats()
              this.$modal.hide('crudModal')
            })
          } else if (this.flow === 'edit') {
            this.$axios
              .post('requests/' + this.item.id, formData, this.config)
              .then(() => {
                this.page = 1
                this.getRequests()
                this.getStats()
                this.$modal.hide('crudModal')
              })
          }
        }
      },

      handleDelete(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text: 'Eliminarás esta información de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: '¡Listo!',
              text: 'El registro fue eliminado',
              icon: 'success',
            })
            this.deleteItem(item)
          }
        })
      },
      async deleteItem(item) {
        this.$axios.delete('requests/' + item.id).then(() => {
          this.page = 1
          this.requests = []
          this.getRequests()
        })
      },

      async getStats() {
        this.loadingStats = true
        const companyId = parseInt(this.selectedCompany.id)
        this.$axios.get('request-stats?company_id=' + companyId).then((response) => {
          this.statusStats = response.data.requestsByStatus
          this.priorityStats = response.data.requestsByPriority

          const time = response.data.attentionTime.split(':')
          if (time[0] !== '00' && time[0] !== '01') {
            this.attentionTime = time[0] + ' horas con ' + time[1] + ' minutos'
          } else if (time[0] === '01') {
            this.attentionTime = '1 hora con ' + time[1] + ' minutos'
          } else if (time[0] === '00' && time[1] !== '00') {
            this.attentionTime = time[1] + ' minutos'
          } else if (time[0] === '00' && time[1] === '00') {
            this.attentionTime = 'Por definir'
          }
          this.loadingStats = false
        })
      },
      getRequestLogs() {
        this.loadingRequestsLogs = true
        this.$axios
          .get(
            'request-logs?order=created_at&by=desc&limit=100&with[]=uploads&request_id=' +
              this.item.id
          )
          .then((response) => {
            this.timelineItems = response.data
            this.loadingRequestsLogs = false
          })
      },
      canceledImageUpload(file) {
        this.$refs.imgDropzone.removeFile(file)
        this.$emit('vdropzone-file-added', file)
      },
      successImageUpload(file, response) {
        this.$refs.imgDropzone.removeFile(file)
        this.item.uploads = response.request.uploads
      },
      sendingImageEvent(file, xhr, formData) {
        formData.append('fileType', 'image')
        formData.append('_method', 'patch')
      },
      uploadError(file, message) {
        if (message.message) {
          this.$swal({
            icon: 'error',
            title: `Error en la carga del archivo ${file.name}`,
            html: `Mensaje: ${message.message} <br /> Por favor, intente nuevamente.`,
            showCancelButton: false,
            showConfirmButton: true,
          })
        } else {
          this.$swal({
            icon: 'error',
            title: `Error en la carga del archivo ${file.name}`,
            html: `Mensaje: ${message} <br /> Por favor, intente nuevamente.`,
            showCancelButton: false,
            showConfirmButton: true,
          })
        }
      },
      async deleteFile(id) {
        this.$axios
          .delete(
            process.env.VUE_APP_CLOUD_API + '/upload-management/delete-upload/' + id
          )
          .then(() => {
            this.$swal({
              icon: 'success',
              title: `Eliminado`,
              showCancelButton: false,
              showConfirmButton: true,
            })
            // this.getRequests();
            this.item.uploads = this.item.uploads.filter((file) => file.id !== id)
          })
      },
      handleSaveTimelineItem() {
        this.expantionControl = []
        this.loadingRequestsLogs = true

        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        formData.append('type', 3)
        formData.append('user_id', this.userId)
        formData.append('request_id', this.item.id)
        if (this.timelineDescription && this.timelineDescription !== '') {
          formData.append('description', this.timelineDescription)
        }
        if (this.timelineSubject && this.timelineSubject !== '') {
          formData.append('subject', this.timelineSubject)
        }
        if (this.selectedTimelineFile) {
          formData.append('file', this.selectedTimelineFile)
          formData.append('fileType', 'image')
        }
        this.$axios.post('request-logs', formData, this.config).then(() => {
          this.getRequestLogs()
          this.loadingRequestsLogs = false
        })
        this.timelineSubject = null
        this.selectedTimelineFile = null
        this.timelineDescription = null
      },
    },
  }
</script>
<style lang="scss">
  .supervisions {
    .v-chip {
      padding: 0 8px;
      margin: 4px;
    }
    .stats {
      .v-chip {
        margin: 4px;
        padding: 0 8px 0 4px;
        .v-chip {
          padding: 0 8px;
          margin: 0 4px 0 0;
        }
      }
    }
    .v-input--radio-group__input {
      flex-direction: row;
      flex-wrap: wrap;
    }

    // .v-data-table-header {
    //   th.sortable {
    //     display: flex;
    //     align-items: center;
    //   }
    // }
    // .v-application--is-ltr.v-chip.v-avatar--left,
    // .v-application--is-ltr.v-chip.v-icon--left {
    //   margin-left: -4px;
    //   margin-right: 4px;
    // }
    .vue-grid-layout {
      background: #eee;
    }
    .vue-grid-item:not(.vue-grid-placeholder) {
      /* background: #ccc; */
      border: 1px solid #ccc;
    }
    .vue-grid-item .resizing {
      opacity: 0.9;
    }
    .vue-grid-item .static {
      background: #cce;
    }
    .vue-grid-item .text {
      font-size: 24px;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
      width: 100%;
    }
    .vue-grid-item .no-drag {
      height: 100%;
      width: 100%;
    }
    .vue-grid-item .minMax {
      font-size: 12px;
    }
    .vue-grid-item .add {
      cursor: pointer;
    }
    .vue-draggable-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
        no-repeat;
      background-position: bottom right;
      padding: 0 8px 8px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: pointer;
    }
    .layoutJSON {
      background: #ddd;
      border: 1px solid black;
      margin-top: 10px;
      padding: 10px;
    }
    .columns {
      -moz-columns: 250px;
      -webkit-columns: 250px;
      columns: 250px;
    }
    .v-skeleton-loader {
      .v-skeleton-loader__chip {
        height: 24px;
        width: 55px;
      }
      .v-skeleton-loader__avatar {
        height: 25px;
        width: 25px;
        margin: 4px;
      }
      .v-input--radio-group__input {
        flex-direction: row;
        flex-wrap: wrap;
      }
      // .v-data-table-header {
      //   th.sortable {
      //     display: flex;
      //     align-items: center;
      //   }
      // }

      .v-application--is-ltr .v-expansion-panel-header__icon {
        margin-left: 20px;
      }
      // .v-application--is-ltr.v-chip.v-avatar--left,
      // .v-application--is-ltr.v-chip.v-icon--left {
      //   margin-left: -4px;
      //   margin-right: 4px;
      // }
      .vue-grid-layout {
        background: #eee;
      }
      .vue-grid-item:not(.vue-grid-placeholder) {
        /* background: #ccc; */
        border: 1px solid #ccc;
      }
      .vue-grid-item .resizing {
        opacity: 0.9;
      }
      .vue-grid-item .static {
        background: #cce;
      }
      .vue-grid-item .text {
        font-size: 24px;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
      }
      .vue-grid-item .no-drag {
        height: 100%;
        width: 100%;
      }
      .vue-grid-item .minMax {
        font-size: 12px;
      }
      .vue-grid-item .add {
        cursor: pointer;
      }
      .vue-draggable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
          no-repeat;
        background-position: bottom right;
        padding: 0 8px 8px 0;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        cursor: pointer;
      }
      .layoutJSON {
        background: #ddd;
        border: 1px solid black;
        margin-top: 10px;
        padding: 10px;
      }
      .columns {
        -moz-columns: 250px;
        -webkit-columns: 250px;
        columns: 250px;
      }
      .v-skeleton-loader {
        .v-skeleton-loader__chip {
          height: 24px;
          width: 55px;
        }
        .v-skeleton-loader__avatar {
          height: 25px;
          width: 25px;
          margin: 4px;
        }
        .skeleton__avatar--x-mall > .v-skeleton-loader__avatar {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
</style>
